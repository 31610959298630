import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f68b0062"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "yh-table" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "handler" }
const _hoisted_5 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "header", {}, () => [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "headerHandler", {}, undefined, true)
        ])
      ], true)
    ]),
    _createVNode(_component_el_table, _mergeProps({
      data: _ctx.listData,
      border: "",
      style: {"width":"100%"},
      onSelectionChange: _ctx.handleSelectionChange
    }, _ctx.childrenProps), {
      default: _withCtx(() => [
        (_ctx.showSelectColumn)
          ? (_openBlock(), _createBlock(_component_el_table_column, {
              key: 0,
              type: "selection",
              align: "center",
              width: "60",
              fixed: "left"
            }))
          : _createCommentVNode("", true),
        (_ctx.showIndexColumn)
          ? (_openBlock(), _createBlock(_component_el_table_column, {
              key: 1,
              type: "index",
              label: "序号",
              align: "center",
              width: "80"
            }))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.propList, (propItem) => {
          return (_openBlock(), _createBlock(_component_el_table_column, _mergeProps({
            key: propItem.prop
          }, propItem, { align: "center" }), {
            default: _withCtx((scope) => [
              _renderSlot(_ctx.$slots, propItem.slotName, {
                row: scope.row
              }, () => [
                _createTextVNode(_toDisplayString(scope.row[propItem.prop]), 1)
              ], true)
            ]),
            _: 2
          }, 1040))
        }), 128)),
        (_ctx.showRightBtn)
          ? (_openBlock(), _createBlock(_component_el_table_column, {
              key: 2,
              label: "操作",
              align: "center",
              width: "100",
              fixed: "right"
            }, {
              default: _withCtx((scope) => [
                _renderSlot(_ctx.$slots, "handler", {
                  row: scope.row
                }, undefined, true)
              ]),
              _: 3
            }))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 16, ["data", "onSelectionChange"]),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.showFooter)
        ? _renderSlot(_ctx.$slots, "footer", { key: 0 }, () => [
            _createVNode(_component_el_pagination, {
              onSizeChange: _ctx.handleSizeChange,
              onCurrentChange: _ctx.handleCurrentChange,
              "current-page": _ctx.page.currentPage,
              "page-sizes": [10, 20, 50, 100, 500],
              "page-size": _ctx.page.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _ctx.listCount
            }, null, 8, ["onSizeChange", "onCurrentChange", "current-page", "page-size", "total"])
          ], true)
        : _createCommentVNode("", true)
    ])
  ]))
}