
import { defineComponent, computed, ref, watch } from 'vue'
import { useStore } from '@/store'
import { ElNotification } from 'element-plus'

import YhTable from '@/base-ui/table'

export default defineComponent({
  props: {
    contentTableConfig: {
      type: Object,
      require: true
    },
    pageName: {
      type: String,
      require: true
    },
    btnName: {
      type: String,
      require: true
    },
    showExportBtn: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean
    },
    showNewBtn: {
      type: Boolean,
      default: true
    },
    showRightBtn: {
      type: Boolean,
      default: true
    },
    showReceiptBtn: {
      type: Boolean,
      default: false
    },
    showRecoveryBtn: {
      type: Boolean,
      default: false
    }
  },
  components: {
    YhTable
  },
  emits: [
    'seeBtnClick',
    'newBtnClick',
    'editBtnClick',
    'exportBtnClick',
    'exportData',
    'recoveryBtnClick',
    'receiveBtnClick',
    'postBtnClick',
    'receiptData'
  ],
  setup(props, { emit }) {
    const store = useStore()

    // 双向绑定pageInfo
    let pageInfo = ref({ currentPage: 1, pageSize: 10 })

    let query = {}
    // 发送网络请求
    const getPageData = (queryInfo: any = {}) => {
      query = queryInfo
      const result = store.dispatch('system/getPageListAction', {
        pageName: props.pageName,
        queryInfo: {
          offset: (pageInfo.value.currentPage - 1) * pageInfo.value.pageSize,
          size: pageInfo.value.pageSize,
          ...queryInfo
        },
        pageInfo
      })
      result.then((res) => {
        if (res.code === -200) {
          ElNotification({
            title: '请求错误',
            message: res.message,
            type: 'error'
          })
        }
      })
    }
    getPageData()
    watch(pageInfo, () => getPageData(query))

    const dateList = computed(() =>
      store.getters[`system/pageListDate`](props.pageName)
    )
    const dateCount = computed(() =>
      store.getters[`system/pageListCount`](props.pageName)
    )

    // 获取其他的动态插槽名称
    const otherPropSlots = props.contentTableConfig?.propList.filter(
      (item: any) => {
        if (item.slotName === 'status') return false
        if (item.slotName === 'createAt') return false
        if (item.slotName === 'updateAt') return false
        if (item.slotName === 'handler') return false
        return true
      }
    )

    // 删除/编辑/查看/新建
    const handleDeleteClick = (item: any) => {
      const result = store.dispatch('system/deletePageDataAction', {
        pageName: props.pageName,
        id: item.id,
        pageInfo,
        query
      })
      result.then((res) => {
        console.log(res)
        if (res.code === 0) {
          ElNotification({
            title: '删除成功！',
            message: res.message,
            type: 'success'
          })
        }
      })
    }

    const handleSeeClick = (item: any) => {
      emit('seeBtnClick', item)
    }

    const handleNewClick = () => {
      emit('newBtnClick')
    }

    const handleEditClick = (item: any) => {
      emit('editBtnClick', item)
    }

    const handleExportClick = () => {
      emit('exportBtnClick')
    }

    const handleRecoveryClick = () => {
      emit('recoveryBtnClick')
    }

    const handleReceiveClick = () => {
      emit('receiveBtnClick')
    }

    const handlePostClick = () => {
      emit('postBtnClick')
    }

    const selectionChange = (item: any) => {
      // console.log(item)
      emit('exportData', item)
      emit('receiptData', item)
    }
    return {
      dateList,
      dateCount,
      getPageData,
      pageInfo,
      otherPropSlots,
      handleDeleteClick,
      handleSeeClick,
      handleNewClick,
      handleEditClick,
      handleExportClick,
      selectionChange,
      handleRecoveryClick,
      handleReceiveClick,
      handlePostClick
    }
  }
})
