import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, mergeProps as _mergeProps, renderList as _renderList, createSlots as _createSlots, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b5ecd796"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-content" }
const _hoisted_2 = { class: "handle-btns" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_popconfirm = _resolveComponent("el-popconfirm")!
  const _component_yh_table = _resolveComponent("yh-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_yh_table, _mergeProps({
      listData: _ctx.dateList,
      listCount: _ctx.dateCount,
      showRightBtn: _ctx.showRightBtn
    }, _ctx.contentTableConfig, {
      page: _ctx.pageInfo,
      "onUpdate:page": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pageInfo) = $event)),
      onSelectionChange: _ctx.selectionChange
    }), _createSlots({
      headerHandler: _withCtx(() => [
        (_ctx.showExportBtn)
          ? (_openBlock(), _createBlock(_component_el_button, {
              key: 0,
              type: "primary",
              size: "medium",
              icon: "el-icon-printer",
              loading: _ctx.loading,
              onClick: _ctx.handleExportClick
            }, {
              default: _withCtx(() => [
                _createTextVNode("导出")
              ]),
              _: 1
            }, 8, ["loading", "onClick"]))
          : _createCommentVNode("", true),
        (_ctx.showRecoveryBtn)
          ? (_openBlock(), _createBlock(_component_el_button, {
              key: 1,
              type: "primary",
              icon: "el-icon-finished",
              size: "small",
              onClick: _ctx.handleRecoveryClick
            }, {
              default: _withCtx(() => [
                _createTextVNode(" 回单回收 ")
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true),
        (_ctx.showReceiptBtn)
          ? (_openBlock(), _createBlock(_component_el_button, {
              key: 2,
              type: "primary",
              icon: "el-icon-finished",
              size: "small",
              onClick: _ctx.handleReceiveClick
            }, {
              default: _withCtx(() => [
                _createTextVNode(" 回单接收 ")
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true),
        (_ctx.showReceiptBtn)
          ? (_openBlock(), _createBlock(_component_el_button, {
              key: 3,
              type: "primary",
              icon: "el-icon-finished",
              size: "small",
              onClick: _ctx.handlePostClick
            }, {
              default: _withCtx(() => [
                _createTextVNode(" 回单寄出 ")
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true),
        (_ctx.showNewBtn)
          ? (_openBlock(), _createBlock(_component_el_button, {
              key: 4,
              icon: "el-icon-edit",
              type: "primary",
              size: "medium",
              onClick: _ctx.handleNewClick
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.btnName), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      status: _withCtx((scope) => [
        _createVNode(_component_el_button, {
          plain: "",
          size: "mini",
          type: scope.row.enable ? 'success' : 'danger'
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(scope.row.enable ? '启用' : '禁用'), 1)
          ]),
          _: 2
        }, 1032, ["type"])
      ]),
      createAt: _withCtx((scope) => [
        _createElementVNode("span", null, _toDisplayString(_ctx.$filters.formatTime(scope.row.createAt)), 1)
      ]),
      updateAt: _withCtx((scope) => [
        _createElementVNode("span", null, _toDisplayString(_ctx.$filters.formatTime(scope.row.updateAt)), 1)
      ]),
      handler: _withCtx((scope) => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_button, {
            size: "mini",
            type: "text",
            onClick: ($event: any) => (_ctx.handleSeeClick(scope.row))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" 查看 ")
            ]),
            _: 2
          }, 1032, ["onClick"]),
          _createVNode(_component_el_button, {
            size: "mini",
            type: "text",
            onClick: ($event: any) => (_ctx.handleEditClick(scope.row))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" 编辑 ")
            ]),
            _: 2
          }, 1032, ["onClick"]),
          _createVNode(_component_el_popconfirm, {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            icon: "el-icon-info",
            iconColor: "red",
            title: "这一条数据确定删除吗？",
            onConfirm: ($event: any) => (_ctx.handleDeleteClick(scope.row))
          }, {
            reference: _withCtx(() => [
              _createVNode(_component_el_button, {
                type: "text",
                size: "mini"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("删除")
                ]),
                _: 1
              })
            ]),
            _: 2
          }, 1032, ["onConfirm"])
        ])
      ]),
      _: 2
    }, [
      _renderList(_ctx.otherPropSlots, (item) => {
        return {
          name: item.slotName,
          fn: _withCtx((scope) => [
            (item.slotName)
              ? _renderSlot(_ctx.$slots, item.slotName, {
                  key: 0,
                  row: scope.row
                }, undefined, true)
              : _createCommentVNode("", true)
          ])
        }
      })
    ]), 1040, ["listData", "listCount", "showRightBtn", "page", "onSelectionChange"])
  ]))
}